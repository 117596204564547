import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_PL } from "./pl/translations";
import { TRANSLATIONS_EN } from "./en/translations";

const getDefaultLanguage = () => {
    const timeZoneData = Intl.DateTimeFormat().resolvedOptions().timeZone
    if( timeZoneData && timeZoneData !== "" && (timeZoneData.includes("Warsaw") || timeZoneData.includes("warsaw")) )
    {
        return "pl"
    }
    return "en"
}

i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
   resources: {
     en: {
       translation: TRANSLATIONS_EN
     },
     pl: {
       translation: TRANSLATIONS_PL
     }
   }
 });

i18n.changeLanguage(getDefaultLanguage());

export default i18n;
