import React, { useState } from 'react'
import { Link as LinkScroll
} from 'react-scroll'
import { Link } from 'react-router-dom'
import { useResizeDetector } from 'react-resize-detector'

import LanguagePicker from './../LanguagePicker'
import { useTranslation } from 'react-i18next'
import '../translations/i18n'

function Menu () {
  const { t } = useTranslation()
  const menuSize = useResizeDetector({ handleWidth: false, handleHeight: true, refreshMode: 'debounce', refreshRate: 1000 })

  const [isButtonActive, setIsButtonActive] = useState(false)
  const handleOnHover = () => {
    setIsButtonActive(true)
  }

  return (
    <>
      <section className="lightmind-menu" ref={menuSize.ref}>
        <div className="lightmind-nav">
          <Link className="lightmind-back-button" to="/"><i className="fas fa-angle-double-left"></i>Powrót</Link>
          <Link className="lightmind-back-button-s" to="/"><i className="fas fa-angle-double-left"></i></Link>
          <div className="lightmind-logo"></div>
          <div className="lightmind-centering-element"></div>

          
       
        </div>
        <LanguagePicker/>
        <section className="buttons">
          <div className="buttons-container">

              <LinkScroll offset={-menuSize.height} to="hardware" smooth={true}>
                <div className="button-content">
                  <i className="fas fa-microchip"></i>
                  <h2>{t('menu_hardware')}</h2>
                </div>
              </LinkScroll>
              <LinkScroll offset={-menuSize.height} to="software" smooth={true}>
                <div className="button-content">
                  <i class="fas fa-laptop-code"></i>
                  <h2>{t('menu_software')}</h2>
                </div>
              </LinkScroll>


              <LinkScroll offset={-menuSize.height} to="wifi" smooth={true}>
                <div className="button-content">
                  <i className="fas fa-wifi"></i>
                  <h2>{t('menu_communication')}</h2>
                </div>
              </LinkScroll>
              <LinkScroll offset={-menuSize.height} to="app" smooth={true}>
                <div className="button-content">
                  <i className="fas fa-mobile-alt"></i>
                  <h2>{t('menu_application')}</h2>
                </div>
              </LinkScroll>

          </div>
        </section>
      </section>
    </>
  )
}

export default Menu
