import React, { Component } from 'react'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import logo1 from './../assets/trophys/kcn.png'
import logo2 from './../assets/trophys/neapco.png'
import logo3 from './../assets/trophys/neomet.svg'
import logo4 from './../assets/trophys/spm.png'

import { withTranslation } from 'react-i18next'
import './translations/i18n'

class Users extends Component {
  render () {
    const settings = {
      dots: true,
      infinite: true,
      speed: 3000,
      autoplay: true,

      autoplaySpeed: 10000,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
    return (
      <div className="users">
        <h2> {this.props.t('users_title')}</h2>
        <Slider {...settings}>
          <div className="divPaddingConst">
            <a href='https://koksownianowa.pl/'>
              <img className='imgFixedRatio' src={logo1}/>
            </a>
          </div>
          <div className="divPaddingConst">
            <a href='https://neapco.com/pl/'>
              <img className='imgFixedRatio' src={logo2}/>
            </a>
          </div>

          <div className="divPaddingConst">
            <a href='https://sistema.com.pl/'>
              <img className='imgFixedRatio' src={logo4}/>
            </a>
          </div>
          <div className="divPaddingConst">
            <a href='https://neomet.com.pl/'>
              <img className='imgFixedRatio' src={logo3}/>
            </a>
          </div>
        </Slider>
      </div>
    )
  }
}

export default withTranslation()(Users)
