import React, { useState, useEffect } from 'react'
import {
  HashRouter as Router
} from 'react-router-dom'

const BannerLoading = () => {
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 4000)
  }, [])

  return (
    <>
      <Router>
        <section className={isLoading ? 'banner-loading' : 'banner-loading hide'}>
          <div className="banner-loading-background"></div>
          <div className="banner-loading-logo"></div>
        </section>
      </Router>
    </>
  )
}

export default BannerLoading
