import React from "react";
import "./App.css";
import {
  HashRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import Home from "./components/Home"
import Lightmind from "./components/lightmind/Lightmind"



function App() {
  return (
    <div>
      <Router>

          <Switch>
            <Route exact path="/" component={Home} />
          </Switch>
        <Route path="/service" component={Lightmind}/>

      </Router>
    </div>
  );
}

export default App;
