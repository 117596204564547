import React, { useState } from 'react'
import {
  HashRouter as Router
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './translations/i18n'

const Cards = () => {
  const { t } = useTranslation()
  const [animation] = useState('topToBottom')

  return (
    <>
      <Router>
        <div className="cards">
          <div className="cards-container" >
              <div id={animation} className="card-container first-row">
                  <i className="fas fa-wifi fa-1.5x circle-icon"></i>
                  <h1>{t('landing_page_cards1_title')}</h1>
                  <p>{t('landing_page_cards1_p')}</p>
              </div>
              <div id={animation} className="card-container first-row">
                  <i className="fas fa-plug fa-1.5x circle-icon"></i>
                  <h1>{t('landing_page_cards2_title')}</h1>
                  <p>{t('landing_page_cards2_p')}</p>
              </div>
              <div id={animation} className="card-container first-row">
                  <i className="fas fa-home fa-1.5x circle-icon"></i>
                  <h1>{t('landing_page_cards3_title')}</h1>
                  <p>{t('landing_page_cards3_p')}</p>
              </div>
              <div id={animation} className="card-container">
                  <i className="fas fa-desktop fa-1.5x circle-icon"></i>
                  <h1>{t('landing_page_cards4_title')}</h1>
                  <p>{t('landing_page_cards4_p')}</p>
              </div>
              <div id={animation} className="card-container">
                  <i className="fas fa-chart-bar fa-1.5x circle-icon"></i>
                  <h1>{t('landing_page_cards5_title')}</h1>
                  <p>{t('landing_page_cards5_p')}</p>
              </div>
          </div>
        </div>
      </Router>
    </>
  )
}

export default Cards
