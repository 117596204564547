import React, { useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import {
  BrowserRouter as Router
} from 'react-router-dom'
import {
  Link as LinkScroll
} from 'react-scroll'
import ScrollUpButton from 'react-scroll-up-button'
import { useTranslation } from 'react-i18next'
import './translations/i18n'

const Navi = () => {
  const [isHamburgerMenuActive, setHamburgerMenuActive] = useState(false)

  const handleToggleMenu = (e) => {
    e.preventDefault()
    setHamburgerMenuActive(prev => !prev)
  }

  const menuSize = useResizeDetector({ handleWidth: false, handleHeight: true, refreshMode: 'debounce', refreshRate: 1000 })

  const { t } = useTranslation()

  return (
    <>
      <Router>
        <button onClick = {handleToggleMenu} className={!isHamburgerMenuActive ? 'hamburger' : 'hamburger hamburger-active'}>
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
        <section className="menu" ref={menuSize.ref}>
          <div className="navi-container">
            <nav>
              <div className="navi-menu-container">
                <div className="navi-logo"></div>
                <ul className={!isHamburgerMenuActive ? 'navi-menu hide' : 'navi-menu'}>
                  <LinkScroll offset={!isHamburgerMenuActive ? -menuSize.height : 0} to="about" smooth={true}>{t('nav_about')}</LinkScroll>
                  <LinkScroll offset={!isHamburgerMenuActive ? -menuSize.height : 0} to="service" smooth={true}>{t('nav_service')}</LinkScroll>
                  <LinkScroll offset={!isHamburgerMenuActive ? -menuSize.height : 0} to="users" smooth={true}>{t('nav_users')}</LinkScroll>
                  <LinkScroll offset={!isHamburgerMenuActive ? -menuSize.height : 0} to="team" smooth={true}>{t('nav_team')}</LinkScroll>
                  <LinkScroll offset={!isHamburgerMenuActive ? -menuSize.height : 0} to="contact" smooth={true}>{t('nav_contact')}</LinkScroll>
                </ul>
              </div>
              <div className="navi-contact-container">
                <ul className="navi-contact">
                  <li><i className="fas fa-envelope"></i>
                    <a href="mailto:biuro@commsoft.pl">biuro@commsoft.pl</a>
                  </li>
                  <li><i className="fas fa-phone"></i>
                    <a href="tel:+48323383980">+48 (32) 338 39 80</a>
                  </li>
                </ul>
              </div>
            </nav>
            <div>
              <ScrollUpButton/>
            </div>
          </div>
        </section>
      </Router>
    </>
  )
}

export default Navi
