import React from 'react'
import {
  HashRouter as Router
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import '../translations/i18n'

const Hardware = () => {
   const { t } = useTranslation()
window.scrollTo(0, 0);
  return (
    <>
      <Router>
        <section className="hardware">
          <div className="hardware-container" style = {{ width: '100%', height: 'auto' }}>
            <h2 smooth="true">{t('lightmind_hardware')}</h2>
            <div className="hardware-content">
              <div className="hardware-img"></div>
              <p>{t('lightmind_hardware_description')}</p>
            </div>
          </div>
        </section>
      </Router>
    </>
  )
}

export default Hardware
