import React from 'react'
import {
  HashRouter as Router
} from 'react-router-dom'

import About from './About'
import Banner from './Banner'
import BannerLoading from './BannerLoading'
import Contact from './Contact'
import Footer from './Footer'
import LanguagePicker from './LanguagePicker'
import Service from './Service'
import Team from './Team'
import Users from './Users'

const Home = () => {
  return (
    <Router>
      <LanguagePicker/>

      <BannerLoading/>
      <Banner/>
      <About/>
      <Service/>
      <Users/>
      <Team/>
      <Contact/>
      <Footer/>
    </Router>

  )
}

export default Home
