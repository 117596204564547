import React, { useState } from 'react'
import {
  Link
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './translations/i18n'
import { EqualHeight, EqualHeightElement } from 'react-equal-height'

const Service = () => {
  const { t } = useTranslation()
  // TODO() decide what to do with lightmind logo
  const [isHover, setIsHover] = useState(false) // eslint-disable-line

  const handleOnMouseEnter = () => {
    setIsHover(true)
  }
  const handleOnMouseLeave = () => {
    setIsHover(false)
  }

  return (

        <section className="service">

          <div className="service-container">
            <div className="service-background"></div>
            <div className="service-content">
              <h1>{t('service_title')}</h1>
              <p>{t('service_p')}</p>

              <div className="service-box-container">
              <EqualHeight>

                <div className="service-box">
                <i className="fas fa-microchip"></i>
                <EqualHeightElement name="Box" placeholder={false} disable={false}>
                  <h2>{t('service_box_title1')}</h2>
                  <p>{t('service_content_p1')}</p>
                  </EqualHeightElement>
                </div>

                <div className="service-box">

                  <i className="fas fa-wifi"></i>
                  <EqualHeightElement name="Box" placeholder={false} disable={false}>
                  <h2>{t('service_box_title2')}</h2>
                  <p>{t('service_content_p2')}</p>
                  </EqualHeightElement>
                </div>

                <div className="service-box">

                  <i className="fas fa-mobile-alt"></i>
                  <EqualHeightElement name="Box" placeholder={false} disable={false}>
                  <h2>{t('service_box_title3')}</h2>
                  <p>{t('service_content_p3')}</p>
                  </EqualHeightElement>
                </div>

              </EqualHeight>
              </div>
              <div className="service-link">
                <Link onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} to="/service">{t('service_button')}</Link>
              </div>
            </div>
          </div>
        </section>
  )
}

export default Service
