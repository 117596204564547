import React from 'react'
import {
  HashRouter as Router
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './translations/i18n'

const Contact = () => {
  const { t } = useTranslation()

  const sendEmail = () => {
    window.location.href = 'mailto:biuro@commsoft.pl'
  }

  return (
    <>
      <Router>
        <section className="contact">
          <div className="contact-container">
            <h1>{t('contact_title')}</h1>
            <div className="contact-content">
                <div className="contact-box" >
                <i className="fas fa-phone"></i>
                    <a href="tel:+48323383980">+48 (32) 338 39 80</a>
                    <a href="tel:+48501866446">+48 501 866 446</a>
                </div>
                <div className="contact-box link-like-hovering" onClick={sendEmail}>
                  <i className="fas fa-envelope" ></i>
                  <p>biuro@commsoft.pl</p>
                </div>
                <a href="https://goo.gl/maps/K1uTBskRqK4nFmTs6" target="_blank" rel="noreferrer">
                  <div className="contact-box">
                    <i className="fas fa-map-marker-alt"></i>
                    <p>CommSOFT Sp. z o.o.</p>
                    <p>ul. Tarnogórska 149</p>
                    <p>44-100 Gliwice</p><br/>
                  </div>
                </a>
                <div className="contact-box">
                <i className="fas fa-address-book"></i>
                    <p>REGON: 389315771</p>
                    <p>NIP: 6312698261</p>
                    <p>KRS 0000908816</p>
                </div>
            </div>
          </div>
        </section>
      </Router>
    </>
  )
}

export default Contact
