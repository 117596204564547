export const TRANSLATIONS_EN = {
    nav_about: "about",
    nav_service: "service",
    nav_users: "current users",
    nav_team: "our team",
    nav_contact: "contact",
    landing_page_cards1_title: "WiFi based",
    landing_page_cards1_p: "Possible utilization of existing WiFi network",
    landing_page_cards2_title: "LED driver 1-10V",
    landing_page_cards2_p: "NO NEED for expensive DALI drivers",
    landing_page_cards3_title: "Made in EU",
    landing_page_cards3_p: "Software and Hardware production in Poland",
    landing_page_cards4_title: "Web\u00A0App",
    landing_page_cards4_p: "Lightning control via PC, tablet, smartphon",
    landing_page_cards5_title: "Energy\u00A0usage data",
    landing_page_cards5_p: "Electric circuits and lighting state time series data ",

    landing_page_banner_text_1: "Wireless lightining management system",
    landing_page_banner_text_2: "Savings up to 90% ",
    landing_page_banner_text_3: "Customer taylored Web Application",
    landing_page_banner_text_4: "Heavy industry validated with more than 1000 working controllers",

    about_title: "about us",
    about_content_p1: "We are IoT technology startup developing wireless lightning management system. Lack of wireless, affordable, intelligent, end user facing system drove us to LightMind prototyping and creation of company. Collaboration with heavy industry users gave us customer needs facing development and solution validation in harsh environments. The result of multiple, challenging but sucessful, cooperations is LightMind - modern, data driven and user friendly wireless lighting management system.",
    service_title: "LightMind",
    service_p: "Affordable and intelligent wireless lighting management system taylored to your needs",
    service_box_title1: "Controllers",
    service_box_title2: "Transport layer",
    service_box_title3: "Progressive Web App",
    service_content_p1: "Real time lights control. Time series lightning state data with energy usage ",
    service_content_p2: "Compliant with IEEE 802.11n wireless comunication standard on 2.4\u00A0GHz band",
    service_content_p3: "Remote light control from web browser enabled devices with no additional software install. Intuitive and fucionalities rich application developed with end users.",
    service_button: "Read More",
    users_title: "Join Early Adopters",
    team_title: "Meet our team",
    team_ceo: "Chief Executive Officer",
    team_cto: "Chief Technology Officer",
    team_cso: "Chief Scientific Officer",
    team_cso_name: "Szymon Rusinowski, PhD",
    contact_title: "contact us",
    lightmind_application: "Web application",
    lightmind_application_description: "LightMind lamp control and automation configuration are managed through a CORE hosted Progressive Web App. There is no need to install any additional software on your device (computer/tablet/smartphone). At the stage of LightMind provisioning we  discuss the customer's user cases and guide him through the first automation configuration to fit his needs. We also provide training and support to allow users to change any part of the LightMind system without costly technician visits on site. Based on user profiles, customers can grant  different access levels to LightMind functionalities.",
    lightmind_hardware: "Hardware",
    lightmind_hardware_description: "LightMind controller is the lowest structural unit of the software platform, which enables control over lighting fixtures and collecting data during operation. One or several luminaires can be connected to each controller. If several luminaires are connected, they are controlled in the same way, however, this solution reduces the cost of the entire investment. Each of the controllers, can be updated without a need for physical access to it, thanks to the OTA (Over The Air programming) protocol.",
    menu_hardware: "Hardware",
    menu_software: "Software",
    menu_communication: "Transport",
    menu_application: "Web application",
    lightmind_communication: "Transport",
    lightmind_communication_description_1: "To ensure communication between the controllers and CORE, LightMind requires WiFi network infrastructure (802.11n 2.4 GHz). It can either use the customer's existing WiFi network infrastructure, or we can build one at the customer's target facility.",
    lightmind_communication_description_2: "",
    lightmind_software: "Software",
    lightmind_software_description_1: "Management of lighting is carried out through a CORE deployed software, responsible for receiving and transmitting information to individual lamps and LightMind Microservices. LightMind Microservices are single task services (e.g. action on motion detection), that enable control of the lighting in a user configurable automated manner. Customer non-standard use cases can be easily addressed with LightMind Microservices architecture, providing solutions tailored to customer's needs.",
    lightmind_software_service: "Standard LightMind deploy Microservices:",
    lightmind_software_service_bullet_1: "Time dependent on/off configuration management (sunrise and sunset included);",
    lightmind_software_service_bullet_2: "Setting the same lightening intensity for a group of lam",
    lightmind_software_service_bullet_3: "Adjustment of lighting intensity to sensor's readings;",
    lightmind_software_service_bullet_4: "Motion detection action management;",
    lightmind_software_service_bullet_5: "Real time collection of data from light controllers and sensors.",
    lightmind_software_description_2: "All data from light controllers, sensors and microservices is stored in a database and can be visualised in a comprehensible, user friendly manner.",
    lightmind_software_description_3: "",
   };
