import React from 'react'
import {
  HashRouter as Router
} from 'react-router-dom'
import { useTranslation } from 'react-i18next' 
import '../translations/i18n'

const Software = () => {
   const { t } = useTranslation() 

  return (
    <>
      <Router>
        <section className="software" style = {{ width: '100%', height: 'auto' }}>
          <div className="software-container" style = {{ width: '100%', height: 'auto' }}>
            <h2 smooth="true">{t('lightmind_software')}</h2>
            <div className="software-content">
                <p>{t('lightmind_software_description_1')}</p><br/>
                <div className="software-img"></div>
              <p> {t('lightmind_software_service')}
                <ul>
                  <li>{t('lightmind_software_service_bullet_1')}</li>
                  <li>{t('lightmind_software_service_bullet_2')}</li>
                  <li>{t('lightmind_software_service_bullet_3')}</li>
                  <li>{t('lightmind_software_service_bullet_4')}</li>
                  <li>{t('lightmind_software_service_bullet_5')}</li>
                </ul>
              </p>
              <p>{t('lightmind_software_description_2')}</p>
              <p>{t('lightmind_software_description_3')}</p>
            </div>
          </div>
        </section>
      </Router>
    </>
  )
}

export default Software
