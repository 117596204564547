import React from 'react'
import i18n from './translations/i18n'

const LanguagePicker = () => {
  const handleOnclick = (e) => {
    e.preventDefault()
    i18n.changeLanguage(e.target.value)
  }
  return (
      <div className="trans-buttons-container" style={{ maxHeight: '10px' }}>
        <div className="trans-buttons" style={{ maxHeight: '10px' }}>
          <button value='pl' onClick={handleOnclick} style={{ maxHeight: '10px' }}>
          </button>
          <button value='en' onClick={handleOnclick} style={{ maxHeight: '10px' }}>
          </button>
        </div>
      </div>

  )
}

export default LanguagePicker
