export const TRANSLATIONS_PL = {
    nav_about: "o nas",
    nav_service: "LightMind",
    nav_users: "zaufali nam",
    nav_team: "zespół",
    nav_contact: "kontakt",
    landing_page_cards1_title: "WiFi system",
    landing_page_cards1_p: "możliwość użycia istniejącej infrastruktury WiFi",
    landing_page_cards2_title: "Oprawy 1-10V",
    landing_page_cards2_p: "nie wymaga zasilacza z protokołem DALI",
    landing_page_cards3_title: "Polski produkt",
    landing_page_cards3_p: "projekt, oprogramowanie i\u00A0elektronika wyprodukowane w\u00A0Polsce",
    landing_page_cards4_title: "Aplikacja",
    landing_page_cards4_p: "sterowanie systemem z poziomu komputera, tabletu lub smartfona",
    landing_page_cards5_title: "Monitoring energii",
    landing_page_cards5_p: "historia zużycia energii, parametrów sieci i\u00A0stanu systemu",
    landing_page_banner_text_1: "Bezprzewodowy system sterowania oświetleniem",
    landing_page_banner_text_2: "Oszczędność energii nawet do 90%",
    landing_page_banner_text_3: "Aplikacja dostosowana do indywidualnych potrzeb klienta",
    landing_page_banner_text_4: "Już ponad 1000 zainstalowanych sterowników",
    about_title: "o nas",
    about_content_p1: "Jesteśmy spółką technologiczną wytwarzającą rozwiązania Internetu Rzeczy (IOT ang. Internet of Things). Motorem napędowym dla uruchomienia działalności było zbudowanie i wdrożenie inteligentnego systemu sterowania oświetleniem dla przemysłu - LightMind, dającego użytkownikom możliwość szerokiej konfiguracji jak i śledzenia stanu.",
    service_title: "LightMind",
    service_p: "Bezprzewodowy system sterowania oświetleniem dostosowany do Twoich potrzeb.",
    service_box_title1: "Sterowniki",
    service_box_title2: "Warstwa transportowa",
    service_box_title3: "Aplikacja",
    service_content_p1: "Sterownik LightMind umożliwia kontrolę i podgląd stanu oprawy wraz z zużyciem prądu",
    service_content_p2: "Standard 802.11n komunikacji bezprzewodowej na paśmie 2.4"+"\u00a0"+"GHz umożliwia użycie istniejącej infrastruktury obniżając koszty montażu",
    service_content_p3: "Skrojona na miarę aplikacja umożliwia zdalne sterowanie systemem poprzez telefon lub komputer. Intuicyjna obsługa oraz funkcjonalności powstające przy współpracy z klientem",
    service_button: "Dowiedz się więcej",
    users_title: "zaufali nam",
    team_title: "nasz zespół",
    team_ceo: "Chief Executive Officer",
    team_cto: "Chief Technology Officer",
    team_cso: "Chief Scientific Officer",
    team_cso_name: "dr Szymon Rusinowski",
    contact_title: "skontaktuj się z nami",
    lightmind_application: "Aplikacja",
    lightmind_application_description: "W ramach dostarczania systemu LightMind dostosowywujemy aplikacje pod klienta i dostarczamy kolejne wersje aplikacji do momentu, w którym inwestor uzyska wszystkie potrzebne mu funkcje na poziomie zarządzania zdalnego. Podejście to umożliwia ograniczenie złożoności sterowania dla użytkownika. Sterowanie systemem może być realizowane lokalnie – dostęp z sieci w której pracują lampy. Dowolne urządzenie posiadające możliwość obsługi przeglądarki łączy się z serwerem aplikacji webowej na komputerem zarządzającym i przy pomocy API pozwala na zmianę parametrów pracy systemu. Na podstawie profilów użytkownika można ograniczyć dostęp do pewnych funkcjonalności.",
    lightmind_hardware: "Elektronika",
    lightmind_hardware_description: "Sterownik LightMind jest produktem sprzętowo jak i pod kątem oprogramowania stworzonym od podstaw przez firmę CommSOFT (prace wdrożeniowe we współpracy z firmą CommLED), umożliwiającym kontrole nad oprawami oraz zbieranie danych podczas użytkowania. Do każdego sterownika można podłączyć jedną oprawę lub kilka opraw (rekomendowana moc podłączonych urządzeń poniżej 600W). W przypadku podłączenia kilku opraw są one sterowane w ten sam sposób jednakże, to rozwiązania ogranicza koszt sterowników dla całej inwestycji. Każdy ze sterowników w systemie posiada unikatowy numer identyfikacyjny (UID), dzięki czemu można zarządząć każdą lampą, każdą grupą lamp pod jednym sterownikiem indywidualnie. Każdy ze sterowników będący częścią systemu można zaktualizować bez konieczności fizycznego dostępu do sterownika dzięki możliwości wykorzystania metody OTA (Over The Air programming).",
    menu_hardware: "Elektronika",
    menu_software: "Oprogramowanie",
    menu_communication: "Warstwa Transportowa",
    menu_application: "Aplikacja",
    lightmind_communication: "",
    lightmind_communication_description_1: "Istnieje możliwość wykorzystania istniejącej infrastruktury na obiekcie inwestora, jeżeli taką posiada. W przypadku braku infrastruktury sieciowej istnieje potrzeba zbudowania tej infrastruktury w celu zapewnienia pokrycia sygnałem WiFi obiektów oraz zapewnienia komunikacji sterowników z komputerem zarządzającym. Infrastruktura składa się z punktów dostępowych, switchy oraz routera w miejscu gdzie będzie komputer przemysłowy oraz ew. komputer z aplikacją stacjonarną.",
    lightmind_communication_description_2: "Duże, realizacje, które uruchamiamy działały w oparciu o infrastrukturę sieciową klienta, które są dobrym standardem przemysłowym przerastającym wielokrotnie wymagania dla systemu LightMind. W praktyce tworzona jest wirtualna sieć VLAN odseparowana od sieci zakładowej (kwestie bezpieczeństwa), na której pracuje system LightMind. Ważnym aspektem w kwestii bezpieczeństwa jest fakt, że zarówno dane (na komputerze przemysłowym) jak i sam system stoi u klienta co ogranicza problemy związane z transferem (rozwiązania w chmurze), bezpieczeństwem i zarządzaniem danymi itp.",
    lightmind_software: "Oprogramowanie",
    lightmind_software_description_1: "Zarządznie z poziomu komputera (Core) odbywa się przy pomocy Brokera, który odpowiedzialny jest za odbieranie oraz przekazywanie informacji do poszczególnych mikroserwisów a tym umożliwiającego sterowanie oświetleniem w zaprogramowany sposób. System działa w oparciu o konfigurację na poziome każdego serwisu oraz interakcje pomiędzy serwisami.",
    lightmind_software_service: "Serwisy wchodzące w skład systemu:",
    lightmind_software_service_bullet_1: "Serwis zarządzający czasowym włączaniem/wyłączaniem konfiguracji (zawiera zegar astronomiczny)",
    lightmind_software_service_bullet_2: "Serwis zarządzający stanami opraw np. Ustawienie lampy: lampa włączona na natężenie 50%, lampa wyłączona",
    lightmind_software_service_bullet_3: "Serwis zarządzający czujnikami natężenia oświetleniem np. Zwiększanie/Zmniejszanie natężenia opraw w celu uzyskania pożądanej wartości natężenia w obszarze pracy czujnika",
    lightmind_software_service_bullet_4: "Serwis zarządzający czujnikami ruchu umożliwiający kontrolę podejmowanych akcji podpiętych pod czujnik w konfiguracji lamp na wykrycie/brak wykrycia ruchu np. Stan wysoki – lampa włączona, natężenie 50%, czas utrzymywania stanu wysokiego 1 min, Stan niski – lampa wyłączona. Wszystkie parametry są konfigurowalne po stronie oprogramowania",
    lightmind_software_service_bullet_5: "Serwis zbierający dane z lamp oraz czujników w czasie rzeczywistym",
    lightmind_software_description_2: "Jeden sterownik lamp może być kontrolowany przez wiele czujników ruchu, jednakże jeden sterownik lamp może być kontrolowany tylko przez jeden czujnik natężenia światła. Każdy z serwisów może być kontrolowany w czasie za pomocą serwisu czasowego.",
    lightmind_software_description_3: "Dostęp do wszystkich danych realizowany jest za pomocą graficznej nakładki dla TSDB współpracującą z wykorzystywaną w systemie bazą danych, jednakże określone dane wprowadzone zostaną do aplikacji mobilnej/stacjonarnej. Za zgodą inwestora na czas czynności serwisowych oprogramowania tworzony jest tunel wsteczny do komputera zarządzającego/ lub generowany jest token do sieci zakładowej przez VPN, który umożliwia firmie prace serwisowe/zmianę ustawień/dodawanie nowych funkcjonalności bez fizycznej obecności technika na obiekcie (Znacząca redukcja kosztów).",
};
