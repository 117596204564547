import React from 'react'
import {
  HashRouter as Router
} from 'react-router-dom'
 import { useTranslation } from 'react-i18next' 
 import '../translations/i18n'

const Application = () => {
   const { t } = useTranslation()

  return (
    <>
      <Router>
        <section className="app" style = {{ width: '100%', height: 'auto' }}>
          <div className="app-container" style = {{ width: '100%', height: 'auto' }}>
            <h2 smooth="true">{t('lightmind_application')}</h2>
            <div className="app-content">
              <div className="app-img-container">
                <div className="app-img"></div>
                <div className="app-img"></div>
                <div className="app-img"></div>
                <div className="app-img"></div>
              </div>
            <p>{t('lightmind_application_description')} </p>
            </div>
          </div>
        </section>
      </Router>
    </>
  )
}

export default Application
