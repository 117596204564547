import { React } from 'react'
import Footer from '../Footer'
import ScrollUpButton from 'react-scroll-up-button'
import Menu from './Menu'
import Hardware from './Hardware'
import Software from './Software'
import Wifi from './Wifi'
import Application from './Application'

function Lightmind () {

  return (
    <div className="lightmind" >
        <Menu />
        <div className="lightmind-content">
          <Hardware />
          <Software/>
          <Wifi/>
          <Application/>
          <Footer />
        </div>
      <div>
        <ScrollUpButton/>
      </div>
    </div>
  )
}

export default Lightmind
