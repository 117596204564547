import React from 'react'
import {
  HashRouter as Router
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './translations/i18n'

const About = () => {
  const { t } = useTranslation()

  return (
    <>
      <Router>
        <section className="about" style = {{ width: '100%', height: 'auto' }}>
          <div className="about-container" style = {{ width: '100%', height: 'auto' }}>
            <h2 >{t('about_title')}</h2>
            <div className="about-content">
              <div className="about-pic"></div>
              <div className="about-text">
                <p>{t('about_content_p1')}</p>
              </div>
            </div>
          </div>
        </section>
      </Router>
    </>
  )
}

export default About
