import React from 'react'
import {
  HashRouter as Router
} from 'react-router-dom'
 import { useTranslation } from 'react-i18next'
 import '../translations/i18n'

const Wifi = () => {
   const { t } = useTranslation()

  return (
    <>
      <Router>
        <section className="wifi" style = {{ width: '100%', height: 'auto' }}>
          <div className="wifi-container" style = {{ width: '100%', height: 'auto' }}>
            <h2 smooth="true">{t('lightmind_communication')}</h2>
            <div className="wifi-content">
              <div className="wifi-text">
                <p>{t('lightmind_communication_description_1')}</p>
                <p>{t('lightmind_communication_description_2')}</p>
              </div>
              <div className="wifi-img"></div>
            </div>
          </div>
        </section>
      </Router>
    </>
  )
}

export default Wifi
