import React from 'react'
import slider1 from './../assets/slider1.jpeg'
import slider2 from './../assets/slider2.jpg'
import slider3 from './../assets/slider3.jpg'
import slider4 from './../assets/slider4.jpg'

import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Navi from './Navi'
import Cards from './Cards'

import { useTranslation } from 'react-i18next'
import './translations/i18n'

function SampleNextArrow (props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, fontSize: '40px', display: 'block', right: '100px', zIndex: '15', height: '40px', width: '40px', opacity: '0.5', color: 'White' }}
      onClick={onClick}
    ><i className="fas fa-angle-right"></i></div>
  )
}

function SamplePrevArrow (props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, fontSize: '40px', display: 'block', right: '100px', zIndex: '15', height: '40px', width: '40px', opacity: '0.5', color: 'White' }}
      onClick={onClick}
    ><i className="fas fa-angle-left"></i></div>
  )
}

const Banner = () => {
  const { t } = useTranslation()
  const settings = {
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }
  return (
    <>
      <Navi/>
      <section className="banner">
        <Slider {...settings}>
          <div className="banner-slider">
            <div className="slider" style={{ backgroundImage: `url("${slider1}")` }}></div>
            <div className="slider-text-container">
              <span className="slider-text">{t('landing_page_banner_text_1')}</span>
            </div>
          </div>
          <div className="banner-slider">
            <div className="slider" style={{ backgroundImage: `url("${slider2}")` }}></div>
            <div className="slider-text-container">
              <span className="slider-text">{t('landing_page_banner_text_2')}</span>
            </div>
          </div>
          <div className="banner-slider">
            <div className="slider" style={{ backgroundImage: `url("${slider3}")` }}></div>
            <div className="slider-text-container">
              <span className="slider-text">{t('landing_page_banner_text_3')}</span>
            </div>
          </div>
          <div className="banner-slider">
            <div className="slider" style={{ backgroundImage: `url("${slider4}")` }}></div>
            <div className="slider-text-container">
              <span className="slider-text">{t('landing_page_banner_text_4')}</span>
            </div>
          </div>
        </Slider>
      </section>
      <Cards />
      </>
  )
}

export default (Banner)
