import React from 'react'
import {
  HashRouter as Router
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './translations/i18n'

const Team = () => {
  const { t } = useTranslation()

  return (
    <>
      <Router>
        <section className="team">
          <div className="team-container">
            <h1>{t('team_title')}</h1>
            <div className="team-content">
                <div className="team-member">
                    <div className="team-img"></div>
                    <h2>Tomasz Rusinowski</h2>
                    <p>{t('team_ceo')}</p>
                </div>
                <div className="team-member">
                    <div className="team-img"></div>
                    <h2>Jan Mikuła</h2>
                    <p>{t('team_cto')}</p>
                </div>
                <div className="team-member">
                    <div className="team-img"></div>
                    <h2>{t('team_cso_name')}</h2>
                    <p>{t('team_cso')}</p>
                </div>
            </div>
          </div>
        </section>
      </Router>
    </>
  )
}

export default Team
