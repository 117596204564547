import React from 'react'
import {
  HashRouter as Router
} from 'react-router-dom'

const Footer = () => {
  return (
    <>
      <Router>
        <footer>
          <div className="footer-container">
            <div className="footer-logo">
              <a href="#" ></a>
            </div>

            <div className="footer-copyrights">
              <p>© CommSOFT 2021. All rights reserved</p>
            </div>
          </div>
        </footer>
      </Router>
    </>
  )
}

export default Footer
